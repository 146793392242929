import React, { SVGProps } from "react";

export const ChevronUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.63274 16.2071C6.02326 16.5976 6.65643 16.5976 7.04695 16.2071L12.3398 10.9142L17.6327 16.2071C18.0233 16.5976 18.6564 16.5976 19.047 16.2071C19.4375 15.8166 19.4375 15.1834 19.047 14.7929L13.047 8.79289C12.8594 8.60539 12.6051 8.5 12.3398 8.5C12.0746 8.5 11.8203 8.60539 11.6327 8.79289L5.63274 14.7929C5.24221 15.1834 5.24221 15.8166 5.63274 16.2071Z"
        fill="#1F2023"
      />
    </svg>
  );
};
