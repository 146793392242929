import React, { SVGProps } from "react";

export const AppStore = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        d="M17.8075 13.1666C17.7851 10.7409 19.8445 9.56087 19.9387 9.50593C18.7723 7.84795 16.9646 7.62142 16.3293 7.60336C14.811 7.44757 13.3382 8.48917 12.5647 8.48917C11.7759 8.48917 10.5848 7.61841 9.30116 7.644C7.6493 7.66883 6.10396 8.6013 5.25642 10.0493C3.5073 13.0018 4.8118 17.3405 6.48759 19.727C7.32587 20.8958 8.30541 22.2008 9.58753 22.1549C10.8419 22.1044 11.3104 21.3752 12.8241 21.3752C14.3239 21.3752 14.7639 22.1549 16.0715 22.1255C17.4177 22.1044 18.2652 20.9515 19.0741 19.7721C20.0429 18.4325 20.4319 17.1132 20.4474 17.0455C20.4157 17.0349 17.8329 16.0739 17.8075 13.1666Z"
        fill="white"
      />
      <path
        d="M15.3374 6.03343C16.012 5.21084 16.4736 4.09173 16.3455 2.95605C15.369 2.9982 14.1479 3.61458 13.4447 4.41911C12.8226 5.12806 12.2668 6.29007 12.4104 7.38284C13.5072 7.46262 14.6334 6.84323 15.3374 6.03343Z"
        fill="white"
      />
    </svg>
  );
};
