import React, { SVGProps } from "react";

export const GooglePlay = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        d="M2.4421 1.21072C2.22021 1.4375 2.0918 1.79058 2.0918 2.24782V18.5576C2.0918 19.0149 2.22021 19.368 2.4421 19.5947L2.49686 19.6445L11.8578 10.5087V10.293L2.49686 1.15725L2.4421 1.21072Z"
        fill="url(#paint0_linear_4654_3795)"
      />
      <path
        d="M14.9743 13.5557L11.8574 10.5089V10.2931L14.978 7.24635L15.0479 7.28599L18.7436 9.33901C19.7983 9.92163 19.7983 10.8804 18.7436 11.4667L15.0479 13.516L14.9743 13.5557Z"
        fill="url(#paint1_linear_4654_3795)"
      />
      <path
        d="M15.0476 13.5166L11.8571 10.4015L2.44141 19.5954C2.79171 19.9549 3.36296 19.9983 4.01258 19.6387L15.0476 13.5166Z"
        fill="url(#paint2_linear_4654_3795)"
      />
      <path
        d="M15.0476 7.28635L4.01258 1.16418C3.36296 0.808339 2.79171 0.851666 2.44141 1.2112L11.8571 10.4014L15.0476 7.28635Z"
        fill="url(#paint3_linear_4654_3795)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4654_3795"
          x1="11.0267"
          y1="18.7275"
          x2="-1.34682"
          y2="6.05419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A0FF" />
          <stop offset="0.0066" stopColor="#00A1FF" />
          <stop offset="0.2601" stopColor="#00BEFF" />
          <stop offset="0.5122" stopColor="#00D2FF" />
          <stop offset="0.7604" stopColor="#00DFFF" />
          <stop offset="1" stopColor="#00E3FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4654_3795"
          x1="20.1169"
          y1="10.4"
          x2="1.83932"
          y2="10.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE000" />
          <stop offset="0.4087" stopColor="#FFBD00" />
          <stop offset="0.7754" stopColor="#FFA500" />
          <stop offset="1" stopColor="#FF9C00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4654_3795"
          x1="13.3127"
          y1="8.70817"
          x2="-3.46674"
          y2="-8.47785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3A44" />
          <stop offset="1" stopColor="#C31162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_4654_3795"
          x1="0.0712806"
          y1="25.0214"
          x2="7.56405"
          y2="17.3472"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32A071" />
          <stop offset="0.0685" stopColor="#2DA771" />
          <stop offset="0.4762" stopColor="#15CF74" />
          <stop offset="0.8009" stopColor="#06E775" />
          <stop offset="1" stopColor="#00F076" />
        </linearGradient>
      </defs>
    </svg>
  );
};
