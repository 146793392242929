import React, { SVGProps } from "react";

export const ChevronDown = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.047 8.79289C18.6564 8.40237 18.0233 8.40237 17.6327 8.79289L12.3398 14.0858L7.04695 8.79289C6.65643 8.40237 6.02326 8.40237 5.63274 8.79289C5.24221 9.18342 5.24221 9.81658 5.63274 10.2071L11.6327 16.2071C11.8203 16.3946 12.0746 16.5 12.3398 16.5C12.6051 16.5 12.8594 16.3946 13.047 16.2071L19.047 10.2071C19.4375 9.81658 19.4375 9.18342 19.047 8.79289Z"
        fill="#1F2023"
      />
    </svg>
  );
};
