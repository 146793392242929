import React from "react";
import { Fade } from "react-awesome-reveal";
import { Button } from "@mui/material";
import { COLORS } from "styles/Colors";
import { LogoBeta } from "Icons";
import ReactGA from "react-ga4";

import ReactPixel from "react-facebook-pixel";
import styled, { css } from "styled-components";

interface FooterStartButtonProps {
  isTablet?: boolean;
  isSmallTablet?: boolean;
  isMobile?: boolean;
  language?: string;
  believer?: boolean;
  onClickJoinBeliever?: () => void;
}

const FooterStartButton = ({
  isTablet,
  isSmallTablet,
  isMobile,
  language,
  believer,
  onClickJoinBeliever,
}: FooterStartButtonProps) => {
  const handleRegisterGtag = (label?: string) => {
    ReactGA.event({
      category: "Button",
      action: "Press Register Button",
      label: label ? label : "Register Button",
    });
  };

  const handleClickJoinBeliever = () => {
    onClickJoinBeliever && onClickJoinBeliever();
  };

  return (
    <Container
      style={{
        padding: isMobile
          ? "0px 20px"
          : isSmallTablet
          ? "0px 20px"
          : isTablet
          ? "0px 30px"
          : "0px",
      }}
    >
      <RegisterWrapper isMobile={isMobile} isTablet={isTablet}>
        <Register isMobile={isMobile} isTablet={isTablet}>
          <LogoBeta />
          <div className="register-title">
            {believer
              ? language === "ko"
                ? "Believer 시작하기"
                : "Start Believer"
              : language === "ko"
              ? "Beta 참여하기"
              : "Join the Beta"}
          </div>
          <div className="register-context">
            {language === "ko"
              ? isMobile
                ? `SLASH는 Beta 버전입니다.\n차세대 업무도구를 누구보다 먼저 만나보세요`
                : `SLASH는 Beta 버전입니다.\n차세대 업무도구를 누구보다 먼저 만나보세요`
              : isMobile
              ? `SLASH is currently in Beta.\nBe the first to experience the next-generation productivity tool`
              : `SLASH is currently in Beta.\nBe the first to experience\nthe next-generation productivity tool`}
          </div>
          {believer ? (
            <Button
              variant="contained"
              size="large"
              href={`https://app.doslash.io?language=${language}`}
              sx={{
                backgroundColor: `${COLORS.sub2}`,
                padding: isMobile ? "14px 16px" : "22px 32px",
                borderRadius: "8px",
                textTransform: "none",
                boxShadow: "0px 4px 16px 0px rgba(45, 106, 223, 0.08)",
              }}
              onClick={() => {
                // handleClickJoinBeliever();
                handleRegisterGtag("Footer");
                ReactPixel.trackCustom("BELIEVER_TRIAL");
              }}
            >
              <MainButtonText>
                {language === "ko" ? "빌리버로 참여하기" : 'Join as "Believer"'}
              </MainButtonText>
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              href={`https://app.doslash.io?language=${language}`}
              sx={{
                backgroundColor: `${COLORS.sub2}`,
                padding: isMobile ? "14px 16px" : "22px 32px",
                borderRadius: "8px",
                textTransform: "none",
                boxShadow: "0px 4px 16px 0px rgba(45, 106, 223, 0.08)",
              }}
              onClick={() => {
                handleRegisterGtag("Footer");
                ReactPixel.trackCustom("START_TRIAL");
              }}
            >
              <MainButtonText>
                {language === "ko" ? "무료로 시작하기" : "Start for Free"}
              </MainButtonText>
            </Button>
          )}
          {believer && (
            <div>
              <span style={{ fontWeight: 700 }}>
                {language === "ko" ? "$200 / 5년" : "$200 / 5 years"}
              </span>
              <span>
                {language === "ko" ? " ($3.3 / 월)" : " ($3.3 / month)"}
              </span>
            </div>
          )}
          <div
            style={{
              width: "100%",
              height: "200%",
              backgroundColor: isMobile ? `#88f4fa` : `#b7fbff`,
              filter: isMobile ? "blur(150px)" : "blur(150px)",
              transform: "translate3d(0, 0, 0)",
              borderRadius: "600px",
              left: isMobile ? "50%" : "55%",
              bottom: isMobile ? "34%" : "50%",
              position: "absolute",
              zIndex: "-1",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "200%",
              backgroundColor: isMobile ? `#CD6CFC` : `#f3dbff`,
              filter: isMobile ? "blur(200px)" : "blur(100px)",
              transform: "translate3d(0, 0, 0)",
              borderRadius: "600px",
              left: isMobile ? "78%" : "60%",
              bottom: isMobile ? "-150%" : "-150%",
              position: "absolute",
              zIndex: "-1",
            }}
          />
          <div
            style={{
              width: "100%",
              height: "200%",
              backgroundColor: "#d7e6ff",
              filter: isMobile ? "blur(60px)" : "blur(180px)",
              transform: "translate3d(0, 0, 0)",
              borderRadius: "1600px",
              left: isMobile ? "-45%" : "-40%",
              position: "absolute",
              zIndex: "-2",
            }}
          />
        </Register>
      </RegisterWrapper>
    </Container>
  );
};

export default FooterStartButton;

const Container = styled.div``;

const RegisterWrapper = styled.div<{
  isMobile?: boolean;
  isTablet?: boolean;
  isSmallTablet?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 160px 0px;

  ${(props) =>
    props.isTablet &&
    css`
      margin-top: 120px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      margin-top: 80px;
    `}
  
    ${(props) =>
    props.isMobile &&
    css`
      margin-top: 48px;
    `}
`;

const Register = styled.div<{
  isMobile?: boolean;
  isTablet?: boolean;
}>`
  width: 90%;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 50px 0px;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .register-title {
    font-family: Pretendard;
    font-size: min(7vw, 40px);
    font-weight: 700;
    line-height: 48px;
    text-align: center;
  }

  .register-context {
    font-family: Pretendard;
    font-weight: 500;
    font-size: min(2.5vw, 24px);
    line-height: min(6.5vw, 36px);
    text-align: center;
    color: ${COLORS.gray600};
    white-space: pre-line;
  }

  ${(props) =>
    (props.isMobile || props.isTablet) &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      padding: 30px 0px;
      height: auto;

      .register-title {
        margin: 10px 0px;
      }

      .register-context {
        margin-bottom: 20px;
        font-size: min(3vw, 24px);
        line-height: min(5.5vw, 36px);
      }
    `}
`;

const MainButtonText = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
`;
