import { Divider, Link, MenuItem, Select } from "@mui/material";
import { LogoBeta } from "Icons";
import React from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { COLORS } from "styles/Colors";

interface FooterProps {
  isMobile?: boolean;
  language?: string;
  onChangeLanguage?: (language: string) => void;
}

const Footer = ({
  isMobile = false,
  language,
  onChangeLanguage,
}: FooterProps) => {
  const handleChangeLanguage = () => {
    if (language === "ko") {
      onChangeLanguage && onChangeLanguage("en");
    } else {
      onChangeLanguage && onChangeLanguage("ko");
    }
  };

  return (
    <Container isMobile={isMobile}>
      <div style={{ width: isMobile ? "100%" : "70%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <LogoBeta fill={COLORS.gray500} width={145} height={48}></LogoBeta>
            <div className="footer-context">Live as you think.</div>
          </div>
          <Select
            value={language}
            onChange={handleChangeLanguage}
            sx={{
              fontSize: "13px",
              height: "42px",
              width: "100px",
              borderRadius: "8px",
            }}
          >
            <MenuItem value={"ko"}>한국어</MenuItem>
            <MenuItem value={"en"}>English</MenuItem>
          </Select>
        </div>
        <Divider
          sx={{
            margin: "40px 0px",
          }}
        />
        <div className="location">
          {language === "ko"
            ? "서울특별시 강남구 역삼동 테헤란로 123 6층"
            : "6F, 123 Teheran-ro, Yeoksam-dong, Gangnam-gu, Seoul, South Korea"}
        </div>
        <div className="footer-bar">
          <div className="footer-bar-text">
            <Link
              underline="none"
              href={`https://terms.doslash.io?language=${language}`}
              target="_blank"
              sx={{
                cursor: "pointer",
                color: `${COLORS.gray500}`,
                marginRight: "20px",
              }}
            >
              Terms of Service
            </Link>
            <Link
              underline="none"
              href={`https://policy.doslash.io?language=${language}`}
              target="_blank"
              sx={{
                cursor: "pointer",
                color: `${COLORS.gray500}`,
                marginRight: "20px",
              }}
            >
              Privacy Policy
            </Link>
          </div>
          <div
            className="footer-bar-copyright"
            style={{ whiteSpace: "pre-line" }}
          >
            Copyright © 2024 SIG. All Rights Reserved.
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;

const Container = styled.div<{ isMobile?: boolean }>`
  width: auto;
  height: fit-content;
  background-color: ${COLORS.gray100};
  color: ${COLORS.gray500};
  padding: 153px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-context {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
    margin-top: 12px;
  }

  .location {
    color: var(--Gray_500, #858994);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
  }

  .email {
    color: var(--Gray_500, #858994);
    font-family: Pretendard;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
  }

  .footer-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.24px;
    margin-top: 32px;
  }

  .footer-bar-text {
    order: 1;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.25px;
  }

  ${(props) =>
    props.isMobile &&
    css`
      padding: 40px 20px;

      .footer-bar {
        flex-direction: column;
      }

      .footer-bar-text {
        order: 0;
        margin-bottom: 12px;
        font-size: 12px;
      }
    `}
`;
