import styled, { css } from "styled-components";
import { Button, Divider, Link } from "@mui/material";
import React, { useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { ChatLogo, DoubleQuotationMark } from "Icons";
import { COLORS } from "styles/Colors";
import Fonts from "styles/Fonts";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useClickOutside } from "@react-hookz/web";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppStore } from "Icons/AppStore";
import { AppStoreText } from "Icons/AppStoreText";
import { GooglePlay } from "Icons/GooglePlay";
import { GooglePlayText } from "Icons/GooglePlayText";

interface ChatBProps {
  isMobile?: boolean;
  isTablet?: boolean;
  isSmallTablet?: boolean;
  isDesktop?: boolean;
  isBigDesktop?: boolean;
  language?: string;
}

const ChatB = ({
  isMobile = false,
  isTablet = false,
  isSmallTablet = false,
  isBigDesktop = false,
  isDesktop = false,
  language,
}: ChatBProps) => {
  const [drawer, setDrawer] = useState(false);
  const refDrawer = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const logoRef = useRef<HTMLImageElement>(null);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [render, setRender] = useState(false);
  const [isLogoLoaded, setIsLogoLoaded] = useState(false);
  const location = useLocation();

  const handleOpenDrawer = () => {
    setDrawer(true);
  };
  const handleCloseDrawer = () => {
    setDrawer(false);
  };

  useClickOutside(refDrawer, () => {
    setTimeout(() => {
      if (drawer) setDrawer(false);
    }, 100);
  });

  useEffect(() => {
    if (isImgLoaded && isLogoLoaded) {
      setTimeout(() => {
        setRender(true);
      }, 200);
    }
  }, []);

  useEffect(() => {
    if (!imgRef.current) return;

    // complete와 naturalHeight를 이용해 완전한 load를 판단하는 함수
    const updateStatus = (img: HTMLImageElement) => {
      const isLoaded = img.complete && img.naturalHeight !== 0;
      setIsImgLoaded(isLoaded);
    };

    // load 이벤트를 바라본다.
    // 익명 함수를 사용했기 때문에 once 속성을 사용해서 한번 실행 후 제거한다.
    imgRef.current.addEventListener(
      "load",
      () => updateStatus(imgRef.current as HTMLImageElement),
      { once: true }
    );
  }, [imgRef]);

  useEffect(() => {
    if (!logoRef.current) return;

    // complete와 naturalHeight를 이용해 완전한 load를 판단하는 함수
    const updateStatus = (img: HTMLImageElement) => {
      const isLoaded = img.complete && img.naturalHeight !== 0;
      setIsLogoLoaded(isLoaded);
    };

    // load 이벤트를 바라본다.
    // 익명 함수를 사용했기 때문에 once 속성을 사용해서 한번 실행 후 제거한다.
    logoRef.current.addEventListener(
      "load",
      () => updateStatus(logoRef.current as HTMLImageElement),
      { once: true }
    );
  }, [logoRef]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleRegisterGtag = (label?: string) => {
    ReactGA.event({
      category: "Button",
      action: "Press Register Button",
      label: label ? label : "Register Button",
    });
  };

  const [visible, setVisible] = useState({
    chat: false,
    organize: false,
    action: false,
  });

  return (
    <Container isBigDesktop={isBigDesktop}>
      <Main className="main">
        <div
          style={{
            background: "linear-gradient(180deg, #fff 0%, #e2ecff 100%)",
          }}
        >
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <MainTextWrapper
              isMobile={isMobile}
              isSmallTablet={isSmallTablet}
              isTablet={isTablet}
            >
              {isMobile && (
                <MainText
                  isMobile={isMobile}
                  isSmallTablet={isSmallTablet}
                  isTablet={isTablet}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Fonts.H1
                      style={{
                        color: COLORS.gray900,
                        fontSize: "min(7vw, 24px)",
                        fontWeight: 700,
                        lineHeight: "36px",
                      }}
                    >
                      {`'선톡'하는 AI 업무 비서`}
                      {/* {language === "ko"
                      ? `알아서 정리되는 '나와의 채팅'`
                      : `Automatically organized 'Chat with Me'`} */}
                    </Fonts.H1>
                  </div>
                  <Fonts.H1
                    style={{
                      margin: "8px 0px 20px 0px",
                      color: `${COLORS.gray600}`,
                      fontWeight: "400",
                      fontSize: `min(4vw, 13px)`,
                      lineHeight: `min(7vw, 20px)`,
                      whiteSpace: "pre-line",
                      textAlign: "center",
                    }}
                  >
                    <span>슬래시챗은 알아서 먼저 챙겨주는</span>
                    <br />
                    <span>
                      스마트한{" "}
                      <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                        AI 업무 비서
                      </span>
                      입니다
                    </span>
                    {/* {language === "ko" ? (
                    <>
                      <span>슬래시챗은 생각나는대로 적어두면</span>
                      <br />
                      <span>
                        알아서 정리해주는
                        <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                          AI 업무 비서
                        </span>
                        입니다
                      </span>
                    </>
                  ) : (
                    <span>
                      SLASH Chat is an
                      <span style={{ color: COLORS.sub2, fontWeight: 700 }}>
                        AI assistant
                      </span>
                      that organizes your thoughts as you write them.
                    </span>
                  )} */}
                  </Fonts.H1>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      target="_blank"
                      href={`https://tally.so/r/w2OoNM`}
                      sx={{
                        height: "36px",
                        backgroundColor: `${COLORS.sub2}`,
                        padding: "8px 16px",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        fontSize: "min(4vw, 13px)",
                        fontWeight: 700,
                      }}
                    >
                      Beta 알림 신청하기
                      {/* {language === "ko"
                      ? "Beta 알림 신청하기"
                      : "Request Beta Notification"} */}
                    </Button>
                  </div>
                </MainText>
              )}
            </MainTextWrapper>
          </Fade>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ marginTop: "50px" }}
          >
            <img
              src={require("../images/chatBMain.png")}
              style={{
                width: "100%",
              }}
            />
          </Fade>
        </div>
        <Fade
          direction="up"
          triggerOnce={true}
          duration={1500}
          style={{ marginTop: "80px" }}
        >
          <QuoteWrapper>
            <div>
              <DoubleQuotationMark />
              <Fonts.H1
                style={{
                  color: COLORS.gray900,
                  fontFamily: "Gowun Batang",
                  fontSize: "min(5vw, 18px)",
                  lineHeight: "min(8vw, 28px)",
                  whiteSpace: "pre-line",
                  marginTop: 18,
                }}
              >
                {`진정한 혁신은 우리의 질문에 답하는\nAI가 아닌, 우리에게 질문하는 AI가\n나타날 때 시작된다.`}
                {/* {language === "ko"
                  ? `'나와의 채팅'에 보내는 메모들,\n쌓아만 두고 있지 않나요?`
                  : `Are you just stacking up the notes you send to 'Chat with Me'?`} */}
              </Fonts.H1>
              <div
                style={{ color: COLORS.brand1, fontSize: 16, marginTop: 14 }}
              >
                - Anonymous
              </div>
            </div>
          </QuoteWrapper>
        </Fade>
        <Fade
          direction="up"
          triggerOnce={true}
          duration={1500}
          style={{ margin: "120px 0px" }}
        >
          <div
            style={{
              fontSize: "min(7vw, 24px)",
              fontWeight: 700,
              lineHeight: "min(10vw, 36px)",
              textAlign: "center",
            }}
          >
            <span>우리에게 좋은 질문을 던지고,</span>
            <br />
            <span>필요한 정보를 먼저 주는</span>
            <br />
            <span>
              <span style={{ color: COLORS.sub2 }}>AI 업무 비서</span>를
              만나보세요
            </span>
            {/* {language === "ko" ? (
              <>
                <span>
                  ✨<span style={{ color: COLORS.sub2 }}>정리</span>는 이제
                </span>
                <br />
                <span>
                  <span style={{ color: COLORS.sub2 }}>슬래시챗</span>에게
                  맡겨요
                </span>
              </>
            ) : (
              <>
                <span>
                  ✨<span style={{ color: COLORS.sub2 }}>Let SLASH</span>
                </span>
                <br />
                <span>
                  <span style={{ color: COLORS.sub2 }}>organize</span> for you
                </span>
              </>
            )} */}
          </div>
        </Fade>
        <Wrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
        >
          <MainFeature
            isMobile={isMobile}
            isSmallTablet={isSmallTablet}
            isTablet={isTablet}
            isVisibleText={visible.chat}
          >
            <div className="feature-text-wrapper">
              <Fade
                direction="up"
                cascade
                triggerOnce
                damping={0.1}
                duration={1500}
                onVisibilityChange={(isVisible) => {
                  setVisible({ ...visible, chat: isVisible });
                }}
              >
                <div className="feature-text-title">
                  <FeatureTextIcon>💬</FeatureTextIcon>
                  <Fonts.H3
                    style={{
                      color: `${COLORS.gray500}`,
                      marginLeft: 8,
                    }}
                  >
                    질문
                    {/* {language === "ko" ? "채팅" : "Chat"} */}
                  </Fonts.H3>
                </div>
                <div className="feature-text">
                  <Fonts.H2
                    style={{
                      fontSize: "min(6vw, 20px)",
                      lineHeight: "min(9vw, 30px)",
                      margin: "12px 0px",
                    }}
                  >
                    <span>
                      <span style={{ color: COLORS.sub2 }}>
                        영감을 주는 질문
                      </span>
                      에 답을 해봐요
                    </span>
                    {/* {language === "ko" ? (
                      <>
                        <span>{`'나에게 채팅'으로`}</span>
                        <br />
                        <span>
                          <span style={{ color: COLORS.sub2 }}>
                            쉽고 빠르게 메모
                          </span>
                          해요
                        </span>
                      </>
                    ) : (
                      <>
                        No more separate lists <br />
                        for daily tasks and <br />
                        long-term projects.
                      </>
                    )} */}
                  </Fonts.H2>
                </div>
                <div
                  className="feature-text"
                  style={{
                    color: COLORS.gray500,
                    fontSize: "min(4vw, 13px)",
                    lineHeight: "min(7vw, 20px)",
                  }}
                >
                  좋은 질문에 답하는 것만으로
                  <br />
                  오늘 하루 생산성이 높아질 수 있어요
                  {/* {language === "ko" ? (
                    <>
                      할 일, 아이디어, 링크 등 머릿속 무엇이든
                      <br />
                      적고 잊어버리세요
                    </>
                  ) : (
                    <>
                      Write down anything that comes to mind
                      <br />
                      and forget it.
                    </>
                  )} */}
                </div>
              </Fade>
            </div>
            <MainFeatureImgWrapper
              isMobile={isMobile}
              isSmallTablet={isSmallTablet}
              isTablet={isTablet}
              style={{ order: isDesktop ? -1 : 1 }}
            >
              <Fade direction="up" delay={100} triggerOnce duration={1500}>
                <img
                  src={
                    language === "ko"
                      ? require("../images/chatQuestion.png")
                      : require("../images/chatQuestion.png")
                  }
                  width="100%"
                  height="auto"
                />
              </Fade>
            </MainFeatureImgWrapper>
          </MainFeature>
        </Wrapper>
        <Wrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
        >
          <MainFeature
            isMobile={isMobile}
            isSmallTablet={isSmallTablet}
            isTablet={isTablet}
            isVisibleText={visible.organize}
          >
            <div className="feature-text-wrapper">
              <Fade
                direction="up"
                cascade
                triggerOnce
                damping={0.1}
                duration={1500}
                onVisibilityChange={(isVisible) => {
                  setVisible({ ...visible, organize: isVisible });
                }}
              >
                <div className="feature-text-title">
                  <FeatureTextIcon>⏰</FeatureTextIcon>
                  <Fonts.H3
                    style={{
                      color: `${COLORS.gray500}`,
                      marginLeft: 8,
                    }}
                  >
                    리마인더
                    {/* {language === "ko" ? "자동 정리" : "Auto-organize"} */}
                  </Fonts.H3>
                </div>
                <div className="feature-text">
                  <Fonts.H2
                    style={{
                      fontSize: "min(6vw, 20px)",
                      lineHeight: "min(9vw, 30px)",
                      margin: "12px 0px",
                    }}
                  >
                    <span>
                      꼼꼼한 비서가{" "}
                      <span style={{ color: COLORS.sub2 }}>리마인더</span>를
                      해줘요
                    </span>
                    {/* {language === "ko" ? (
                      <>
                        <span>
                          슬래시챗이{" "}
                          <span style={{ color: COLORS.sub2 }}>
                            똑똑하게 정리
                          </span>
                          해드려요
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          SLASH Chat{" "}
                          <span style={{ color: COLORS.sub2 }}>
                            automatically organizes
                          </span>
                        </span>
                      </>
                    )} */}
                  </Fonts.H2>
                </div>
                <div
                  className="feature-text"
                  style={{
                    color: COLORS.gray500,
                    fontSize: "min(4vw, 13px)",
                    lineHeight: "min(7vw, 20px)",
                    whiteSpace: "pre-line",
                  }}
                >
                  {`예약 메시지를 설정하듯 리마인더를 요청하면,\n슬래시챗이 딱 맞춰 알려줘요`}
                  {/* {language === "ko"
                    ? "태스크, 메모, 링크로 자동으로 분류해줘요"
                    : "Automatically categorize tasks, memos, and links"} */}
                </div>
              </Fade>
            </div>
            <MainFeatureImgWrapper
              isMobile={isMobile}
              isSmallTablet={isSmallTablet}
              isTablet={isTablet}
            >
              <Fade direction="up" delay={100} triggerOnce duration={1500}>
                <img
                  src={require(language === "ko"
                    ? "../images/chatReminder.png"
                    : "../images/chatReminder.png")}
                  width="100%"
                  height="auto"
                />
              </Fade>
            </MainFeatureImgWrapper>
          </MainFeature>
        </Wrapper>
        <Wrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
        >
          <MainFeature
            isMobile={isMobile}
            isSmallTablet={isSmallTablet}
            isTablet={isTablet}
            isVisibleText={visible.action}
          >
            <div className="feature-text-wrapper">
              <Fade
                direction="up"
                cascade
                triggerOnce
                damping={0.1}
                duration={1500}
                onVisibilityChange={(isVisible) => {
                  setVisible({ ...visible, action: isVisible });
                }}
              >
                <div className="feature-text-title">
                  <FeatureTextIcon>📩</FeatureTextIcon>
                  <Fonts.H3
                    style={{
                      color: `${COLORS.gray500}`,
                      marginLeft: 8,
                    }}
                  >
                    모닝 브리프
                    {/* {language === "ko" ? "실행" : "Action"} */}
                  </Fonts.H3>
                </div>
                <div className="feature-text">
                  <Fonts.H2
                    style={{
                      fontSize: "min(6vw, 20px)",
                      lineHeight: "min(9vw, 30px)",
                      margin: "12px 0px",
                    }}
                  >
                    <span>
                      매일 아침
                      <span style={{ color: COLORS.sub2 }}>맞춤 메시지</span>를
                      받아보세요
                    </span>
                    {/* {language === "ko" ? (
                      <>
                        <span>
                          <span style={{ color: COLORS.sub2 }}>PC 웹</span>
                          으로 본격적으로 실행해봐요
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          Let’s get started on the
                          <span style={{ color: COLORS.sub2 }}> PC web</span>
                        </span>
                      </>
                    )} */}
                  </Fonts.H2>
                </div>
                <div
                  className="feature-text"
                  style={{
                    color: COLORS.gray500,
                    fontSize: "min(4vw, 13px)",
                    lineHeight: "min(7vw, 20px)",
                  }}
                >
                  &apos;오늘의 날씨&apos;부터 &apos;일정 요약&apos;까지
                  <br />
                  하루의 시작도 성공적으로 함께 해요
                  {/* {language === "ko" ? (
                    <>
                      슬래시챗과 연동되는 파워풀한 플래너
                      <br />
                      이제 모바일로 쓴 걸 PC로 옮겨 적을 필요 없어요
                    </>
                  ) : (
                    <>
                      A powerful planner that syncs with SLASH Chat
                      <br />
                      No need to transfer notes from mobile to PC
                    </>
                  )} */}
                </div>
              </Fade>
            </div>
            <MainFeatureImgWrapper
              isMobile={isMobile}
              isSmallTablet={isSmallTablet}
              isTablet={isTablet}
              style={{ order: isDesktop ? -1 : 1 }}
            >
              <Fade
                direction="up"
                triggerOnce={true}
                delay={100}
                duration={1500}
              >
                <img
                  src={require(language === "ko"
                    ? "../images/chatBrief.png"
                    : "../images/chatBrief.png")}
                  width="100%"
                  height="auto"
                />
              </Fade>
            </MainFeatureImgWrapper>
          </MainFeature>
        </Wrapper>
        <SlashSubFeatureWrapper
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          isTablet={isTablet}
          isBigDesktop={isBigDesktop}
        >
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <SlashSubFeatureHeader
              style={{
                textAlign: "center",
                fontSize: `min(7vw, 24px)`,
                lineHeight: `min(10vw, 36px)`,
              }}
            >
              <span>
                <span style={{ color: COLORS.sub2 }}>나의 첫 AI 업무 비서</span>
                를
              </span>
              <span>만나보세요</span>
              {/* {language === "ko" ? (
                <>
                  <span>
                    <span style={{ color: COLORS.sub2 }}>
                      나의 첫 AI 업무 비서
                    </span>
                    를
                  </span>
                  <span>만나보세요</span>
                </>
              ) : (
                <>
                  <span>Meet your first</span>
                  <span style={{ color: COLORS.sub2 }}> AI assistant</span>
                </>
              )} */}
            </SlashSubFeatureHeader>
          </Fade>
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "min(4vw, 13px)",
                fontWeight: 700,
                backgroundColor: COLORS.sub2,
                borderRadius: "30px",
                color: COLORS.white,
                lineHeight: "20px",
                margin: "32px 0px 16px 0px",
                padding: "4px 12px",
              }}
            >
              출시 예정
              {/* {language === "ko" ? "출시 예정" : "Coming Soon"} */}
            </div>
          </Fade>
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <AppDisplayWrapper>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: COLORS.gray800,
                  borderRadius: "32px",
                  marginRight: "12px",
                  padding: "12px 24px",
                }}
              >
                <AppStore />
                <AppStoreText style={{ marginLeft: "12px" }} />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: COLORS.gray800,
                  borderRadius: "32px",
                  padding: "12px 24px",
                }}
              >
                <GooglePlay />
                <GooglePlayText style={{ marginLeft: "12px" }} />
              </div>
            </AppDisplayWrapper>
          </Fade>
        </SlashSubFeatureWrapper>
        <ChatNotificationWrapper>
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <ChatNotification>
              <ChatLogo fill={COLORS.white} />
              <span
                style={{
                  fontSize: "min(6vw, 20px)",
                  fontWeight: 700,
                  margin: "18.5px 0px 12px 0px",
                }}
              >
                Beta 신청하기
              </span>
              <span
                style={{
                  fontSize: "min(4vw, 13px)",
                  marginBottom: "20px",
                  whiteSpace: "pre-line",
                  lineHeight: "min(7vw, 20px)",
                  textAlign: "center",
                }}
              >
                {`슬래시챗은 Beta 런칭을 준비하고 있어요
                미리 신청해주시면 Beta
                런칭시 가장 빠르게 알려드릴게요!`}
              </span>
              <Button
                variant="contained"
                size="small"
                target="_blank"
                href={`https://tally.so/r/w2OoNM`}
                sx={{
                  backgroundColor: `${COLORS.sub2}`,
                  padding: "14px 16px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                  fontSize: "min(4vw, 13px)",
                  fontWeight: 700,
                }}
              >
                Beta 알림 신청하기
                {/* {language === "ko"
                  ? "Beta 알림 신청하기"
                  : "Request Beta Notification"} */}
              </Button>
            </ChatNotification>
          </Fade>
        </ChatNotificationWrapper>
      </Main>
      <Footer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ChatLogo width={132} height={30} fill={COLORS.white} />
          <span
            style={{
              color: COLORS.white,
              fontWeight: 500,
              lineHeight: `min(7vw, 18px)`,
              marginTop: 12,
            }}
          >
            Your First AI Sidekick
          </span>
        </div>
        <Divider
          sx={{
            backgroundColor: COLORS.gray500,
            margin: "20px 0px",
          }}
        />
        <div
          style={{
            color: COLORS.gray500,
            fontSize: "min(4vw, 12px)",
            marginBottom: 20,
          }}
        >
          서울특별시 강남구 역삼동 테헤란로 123 6층
          {/* {language === "ko"
            ? "서울특별시 강남구 역삼동 테헤란로 123 6층"
            : "6F, 123 Teheran-ro, Yeoksam-dong, Gangnam-gu, Seoul, South Korea"} */}
        </div>
        <div style={{ display: "flex", marginBottom: 20 }}>
          <Link
            underline="none"
            href={`https://terms.doslash.io?language=${language}`}
            target="_blank"
            sx={{
              cursor: "pointer",
              color: `${COLORS.gray500}`,
              marginRight: "20px",
              fontSize: "min(4vw, 12px)",
              fontWeight: 700,
            }}
          >
            Terms of Service
          </Link>
          <Link
            underline="none"
            href={`https://policy.doslash.io?language=${language}`}
            target="_blank"
            sx={{
              cursor: "pointer",
              color: `${COLORS.gray500}`,
              marginRight: "20px",
              fontSize: "min(4vw, 12px)",
              fontWeight: 700,
            }}
          >
            Privacy Policy
          </Link>
        </div>
        <div style={{ color: COLORS.gray500, fontSize: "min(3vw, 10px)" }}>
          Copyright © 2024 SIG. All Rights Reserved.
        </div>
      </Footer>
    </Container>
  );
};

export default ChatB;

const Container = styled.div<{ isBigDesktop?: boolean }>`
  ${(props) =>
    props.isBigDesktop &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

  .main {
    ${(props) =>
      props.isBigDesktop &&
      css`
        max-width: 1600px;
      `}
  }

  .MuiButton-contained {
    box-shadow: 0px 12px 40px 0px rgba(45, 106, 223, 0.12);
  }
`;

const Wrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 140px;
  box-sizing: border-box;
  padding: 0px 100px;

  ${(props) =>
    props.isMobile &&
    css`
      padding: 0px 20px;
      margin-top: 80px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      padding: 0px 10px;
      margin-top: 120px;
    `}

  ${(props) =>
    props.isTablet &&
    css`
      padding: 0px 10px;
      margin-top: 120px;
    `}
`;

const Main = styled.div`
  .slash-intro {
    display: flex;
    padding: 0px 20px;
  }

  .slash-main {
    padding: 60px 0px;
  }

  .slash-inbox {
    padding: 0px 20px;
  }

  .slash-planning {
    padding: 0px 20px;
  }

  .slash-timeboxing {
    padding: 0px 20px;
  }

  .slash-linkage {
    padding: 0px 20px;
  }
`;

const MainTextWrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  height: 700px;
  width: 100%;
  display: flex;
  position: relative;

  ${(props) =>
    props.isMobile &&
    css`
      justify-content: center;
      height: auto;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      margin-top: 32px;
    `}

  ${(props) =>
    props.isTablet &&
    css`
      height: 500px;
    `}
`;

const MainText = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  margin-top: 130px;
  padding-left: 100px;

  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 32px;
      padding-left: 0px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      margin-top: 0px;
    `}
  

  ${(props) =>
    props.isTablet &&
    css`
      margin-top: 160px;
    `}
`;

const MainFeature = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
  isVisibleText?: boolean;
}>`
  width: 100%;
  /* max-width: 1300px; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  .feature-text-wrapper {
    opacity: 0;
  }

  ${(props) =>
    props.isVisibleText &&
    css`
      .feature-text-wrapper {
        opacity: 1;
      }
    `}

  .feature-text-title {
    display: flex;
    align-items: center;
    ${(props) =>
      (props.isMobile || props.isSmallTablet || props.isTablet) &&
      css`
        justify-content: center;
      `}
  }

  .feature-img {
    ${(props) =>
      props.isMobile &&
      css`
        margin-top: 45px;
      `}

    ${(props) =>
      props.isTablet &&
      css`
        margin-top: 60px;
      `}
  }

  ${(props) =>
    (props.isMobile || props.isSmallTablet || props.isTablet) &&
    css`
      flex-direction: column;
      align-items: center;

      .feature-text-wrapper {
        text-align: center;
      }
    `}
`;

const RegisterWrapper = styled.div<{
  isMobile?: boolean;
  isTablet?: boolean;
  isSmallTablet?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 160px 0px;

  ${(props) =>
    props.isTablet &&
    css`
      margin-top: 120px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      margin-top: 80px;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 48px;
    `}
`;

const Register = styled.div<{
  isMobile?: boolean;
  isTablet?: boolean;
}>`
  width: 90%;
  height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 50px 0px;
  border-radius: 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .register-title {
    font-family: Pretendard;
    font-size: min(7vw, 40px);
    font-weight: 700;
    line-height: 48px;
    text-align: center;
  }

  .register-context {
    font-family: Pretendard;
    font-weight: 500;
    font-size: min(2.5vw, 24px);
    line-height: min(6.5vw, 36px);
    text-align: center;
    color: ${COLORS.gray600};
    white-space: pre-line;
  }

  ${(props) =>
    (props.isMobile || props.isTablet) &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      padding: 30px 0px;
      height: auto;

      .register-title {
        margin: 10px 0px;
      }

      .register-context {
        margin-bottom: 20px;
        font-size: min(3vw, 24px);
        line-height: min(5.5vw, 36px);
      }
    `}
`;

const MainButtonText = styled.div`
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
`;

const SlashSubFeatureWrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
  isBigDesktop?: boolean;
}>`
  margin-top: 70px;
  background-color: ${COLORS.gray900};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;

  ${(props) =>
    props.isBigDesktop &&
    css`
      border-radius: 20px;
    `}

  ${(props) =>
    props.isTablet &&
    css`
      padding: 120px 80px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      padding: 120px 60px;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      padding: 80px 20px;
    `}
`;

const SlashSubFeatureHeader = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.white};
  font-weight: 700;
`;

const SlashSubFeatureContent = styled.div<{
  isSmallTablet?: boolean;
  isMobile?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;

  & div:nth-child(4),
  & div:nth-child(5),
  & div:nth-child(6) {
    margin-top: 16px;
  }

  ${(props) =>
    props.isMobile &&
    css`
      & div:nth-child(3),
      & div:nth-child(4),
      & div:nth-child(5),
      & div:nth-child(6) {
        margin-top: 16px;
      }
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      & div:nth-child(3),
      & div:nth-child(4),
      & div:nth-child(5),
      & div:nth-child(6) {
        margin-top: 16px;
      }
    `}
`;

const SlashSubFeatureDetail = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  width: 100%;
  height: 240px;
  padding: 40px;
  border: 1px solid ${COLORS.gray100};
  box-shadow: 0px 4px 16px 0px rgba(45, 106, 223, 0.08);
  background-color: ${COLORS.white};
  border-radius: 20px;
  box-sizing: border-box;

  ${(props) =>
    props.isTablet &&
    css`
      padding: 32px;
    `}

  ${(props) =>
    props.isSmallTablet &&
    css`
      padding: 32px;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      height: 100%;
      padding: 32px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `}

  .sub-feature-title {
    font-size: 16px;
    margin: 20px 0px 8px 0px;
    font-weight: 700;

    ${(props) =>
      props.isMobile &&
      css`
        margin: 8px 0px 0px 0px;
        line-height: 16px;
      `}
  }
`;

const IconWrapper = styled.div`
  background-color: ${COLORS.sub3};
  border-radius: 8px;
  padding: 4px;
`;

const FeatureTextIcon = styled.div`
  font-size: 24px;
`;

const MainFeatureImgWrapper = styled.div<{
  isMobile?: boolean;
  isSmallTablet?: boolean;
  isTablet?: boolean;
}>`
  width: 55%;

  .planning {
    order: -1;
  }

  ${(props) =>
    (props.isMobile || props.isSmallTablet || props.isTablet) &&
    css`
      width: 100%;
      margin-top: 40px;
    `}
`;

const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const AppDisplayWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChatNotificationWrapper = styled.div`
  background: linear-gradient(180deg, #1f2023 0%, #33353a 100%);
  padding: 44px 20px;
`;

const ChatNotification = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #3e4046 0%, #2a2c30 100%);
  border: 1px solid #3e4046;
  border-radius: 20px;
  color: ${COLORS.white};
  mix-blend-mode: overlay;
  padding: 32px 0px;
`;

const Footer = styled.div<{ isMobile?: boolean }>`
  background-color: #000;
  padding: 40px 20px;
`;
