import React, { SVGProps } from "react";

export const AppStoreText = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="13"
      viewBox="0 0 57 13"
      fill="none"
      {...props}
    >
      <path
        d="M8.16756 9.8614H6.56239L5.68313 7.16758H2.62688L1.78932 9.8614H0.226562L3.25453 0.69043H5.12475L8.16756 9.8614ZM5.41807 6.03741L4.62291 3.64267C4.5388 3.39803 4.38118 2.82192 4.14864 1.91502H4.12037C4.02778 2.30507 3.87864 2.88118 3.67367 3.64267L2.89264 6.03741H5.41807Z"
        fill="white"
      />
      <path
        d="M15.9554 6.4732C15.9554 7.59786 15.6437 8.48685 15.0203 9.13945C14.462 9.72039 13.7686 10.0105 12.9409 10.0105C12.0475 10.0105 11.4057 9.69765 11.0149 9.07192H10.9866V12.5555H9.47967V5.42503C9.47967 4.71798 9.46058 3.99233 9.42383 3.24806H10.7491L10.8332 4.29623H10.8615C11.364 3.50649 12.1267 3.1123 13.1501 3.1123C13.9502 3.1123 14.6182 3.42035 15.1525 4.03712C15.6883 4.65458 15.9554 5.46638 15.9554 6.4732ZM14.4203 6.52695C14.4203 5.8833 14.2718 5.35267 13.9736 4.93506C13.6477 4.49953 13.2102 4.28176 12.6617 4.28176C12.2899 4.28176 11.9521 4.40305 11.6503 4.64218C11.3478 4.88337 11.1499 5.19831 11.0573 5.58836C11.0106 5.77029 10.9873 5.91914 10.9873 6.03629V7.1389C10.9873 7.61992 11.1385 8.02582 11.4411 8.35729C11.7436 8.68876 12.1366 8.85415 12.62 8.85415C13.1876 8.85415 13.6293 8.64052 13.9453 8.21464C14.2619 7.78806 14.4203 7.22573 14.4203 6.52695Z"
        fill="white"
      />
      <path
        d="M23.7555 6.4732C23.7555 7.59786 23.4438 8.48685 22.8197 9.13945C22.262 9.72039 21.5687 10.0105 20.741 10.0105C19.8476 10.0105 19.2058 9.69765 18.8156 9.07192H18.7874V12.5555H17.2804V5.42503C17.2804 4.71798 17.2614 3.99233 17.2246 3.24806H18.5499L18.634 4.29623H18.6623C19.1641 3.50649 19.9267 3.1123 20.9509 3.1123C21.7503 3.1123 22.4182 3.42035 22.954 4.03712C23.4876 4.65458 23.7555 5.46638 23.7555 6.4732ZM22.2203 6.52695C22.2203 5.8833 22.0712 5.35267 21.7729 4.93506C21.4471 4.49953 21.011 4.28176 20.4618 4.28176C20.0893 4.28176 19.7522 4.40305 19.4496 4.64218C19.1471 4.88337 18.9499 5.19831 18.8573 5.58836C18.8114 5.77029 18.7874 5.91914 18.7874 6.03629V7.1389C18.7874 7.61992 18.9386 8.02582 19.2397 8.35729C19.5422 8.68807 19.9352 8.85415 20.4201 8.85415C20.9877 8.85415 21.4294 8.64052 21.7454 8.21464C22.062 7.78806 22.2203 7.22573 22.2203 6.52695Z"
        fill="white"
      />
      <path
        d="M32.478 7.28917C32.478 8.06927 32.2002 8.70396 31.6425 9.19393C31.0297 9.72939 30.1766 9.99677 29.0804 9.99677C28.0682 9.99677 27.2568 9.80657 26.6426 9.42548L26.9917 8.2009C27.6533 8.59094 28.3792 8.78666 29.1701 8.78666C29.7377 8.78666 30.1795 8.66124 30.4968 8.41177C30.8128 8.1623 30.9704 7.82739 30.9704 7.40977C30.9704 7.03764 30.8403 6.72409 30.5795 6.4698C30.3201 6.21551 29.8868 5.97913 29.2818 5.76068C27.6349 5.16182 26.8122 4.28456 26.8122 3.13095C26.8122 2.37704 27.1006 1.75889 27.6781 1.27787C28.2534 0.796171 29.021 0.555664 29.9808 0.555664C30.8368 0.555664 31.5478 0.701071 32.1154 0.991196L31.7387 2.18891C31.2086 1.90774 30.6092 1.76716 29.9384 1.76716C29.4083 1.76716 28.9941 1.89465 28.6973 2.14825C28.4464 2.37497 28.3205 2.65132 28.3205 2.97865C28.3205 3.34114 28.464 3.64091 28.7524 3.87659C29.0033 4.09436 29.4592 4.33004 30.1208 4.58433C30.9301 4.90202 31.5245 5.27346 31.9069 5.69935C32.2879 6.12385 32.478 6.65517 32.478 7.28917Z"
        fill="white"
      />
      <path
        d="M37.4607 4.35091H35.7997V7.56158C35.7997 8.3782 36.0923 8.78617 36.6789 8.78617C36.9482 8.78617 37.1716 8.76342 37.3483 8.71794L37.39 9.83365C37.0931 9.94184 36.7023 9.99628 36.2181 9.99628C35.623 9.99628 35.1579 9.81918 34.8222 9.46565C34.4878 9.11144 34.3196 8.51741 34.3196 7.68287V4.34953H33.3301V3.24692H34.3196V2.03612L35.7997 1.60059V3.24692H37.4607V4.35091Z"
        fill="white"
      />
      <path
        d="M44.9538 6.50037C44.9538 7.51684 44.6556 8.35137 44.0604 9.00398C43.4363 9.67589 42.6079 10.0108 41.5753 10.0108C40.5801 10.0108 39.7878 9.68898 39.1969 9.04533C38.606 8.40168 38.3105 7.58919 38.3105 6.60994C38.3105 5.5852 38.6145 4.74584 39.2244 4.09323C39.833 3.43993 40.6543 3.11328 41.687 3.11328C42.6822 3.11328 43.483 3.43511 44.0873 4.07944C44.6655 4.70449 44.9538 5.51146 44.9538 6.50037ZM43.3904 6.54791C43.3904 5.93803 43.2568 5.41498 42.9861 4.97876C42.6701 4.45089 42.2185 4.18764 41.6333 4.18764C41.0275 4.18764 40.5674 4.45158 40.2514 4.97876C39.9807 5.41567 39.8472 5.94699 39.8472 6.57548C39.8472 7.18536 39.9807 7.70841 40.2514 8.14394C40.5773 8.67182 41.0325 8.93507 41.6198 8.93507C42.1952 8.93507 42.6468 8.66631 42.9727 8.13016C43.2504 7.68567 43.3904 7.15711 43.3904 6.54791Z"
        fill="white"
      />
      <path
        d="M49.8514 4.54019C49.7023 4.51331 49.5433 4.49953 49.3764 4.49953C48.8463 4.49953 48.4364 4.69455 48.148 5.08529C47.8971 5.42986 47.7713 5.86539 47.7713 6.3912V9.86098H46.2651L46.2792 5.33062C46.2792 4.56844 46.2601 3.87448 46.2227 3.24875H47.5352L47.5903 4.514H47.632C47.7911 4.07916 48.042 3.72908 48.3855 3.46652C48.7212 3.23015 49.0838 3.1123 49.4747 3.1123C49.6139 3.1123 49.7398 3.12195 49.8514 3.13918V4.54019Z"
        fill="white"
      />
      <path
        d="M56.5921 6.24234C56.5921 6.50559 56.5744 6.72749 56.537 6.90873H52.0162C52.0339 7.56203 52.2523 8.06165 52.6722 8.40621C53.0531 8.71425 53.5458 8.86862 54.1508 8.86862C54.8201 8.86862 55.4308 8.76456 55.98 8.55575L56.2161 9.57567C55.5743 9.84857 54.8166 9.98432 53.9423 9.98432C52.8906 9.98432 52.065 9.68249 51.4642 9.07949C50.8648 8.4765 50.5645 7.66677 50.5645 6.65099C50.5645 5.65382 50.8436 4.82342 51.4027 4.16116C51.988 3.45411 52.7789 3.10059 53.7741 3.10059C54.7516 3.10059 55.4916 3.45411 55.9942 4.16116C56.3921 4.7228 56.5921 5.41745 56.5921 6.24234ZM55.1552 5.86125C55.1651 5.42572 55.0668 5.04945 54.8626 4.73176C54.6017 4.32311 54.201 4.11912 53.6617 4.11912C53.169 4.11912 52.7683 4.31828 52.4622 4.71798C52.2113 5.03567 52.0622 5.41676 52.0162 5.86056H55.1552V5.86125Z"
        fill="white"
      />
    </svg>
  );
};
